
.dashboard {
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 50px;
}

.counter {
  border-radius: 5px;
  border: 1px solid #E8E8E8;
  background-color: #FEFEFE;
  height: auto;
  padding: 40px 30px; 
}

.number {
  color: #3D3E46;
  font-size: 200px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 30px;
}

.icon-box{
  width: 180px;
  height: 42px;
}

header{
  margin-bottom: 50px;
}

.number p {
  font-size: 32px;
  line-height: 40px;
  display: flex;
}



