@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  font-family:  'Roboto';
  font-size: 24px;
  font-style: normal;
  color:  #3D3E46;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F9F8;
}

code {
  font-family:'Roboto';
}
